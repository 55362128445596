.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    display: flex;
    flex-direction: column;
    height: 100%;
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
    height: 200px;
}

.portfolio__item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 1.5rem;
}

.portfolio__item-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.portfolio__item h3 {
    margin: 1rem 0;
}

.portfolio__item p {
    margin: 0.5rem 0;
    font-size: 0.9rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem; /* Space between buttons */
    margin-top: auto; /* Push buttons to the bottom */
}

.portfolio__item-cta a {
    flex: 1; /* Allow buttons to grow equally */
    padding: 0.5rem 1rem; /* Add padding for better appearance */
    text-align: center; /* Center the text */
    border-radius: 5px; /* Optional: Add border radius */
    transition: background-color 0.3s ease; /* Optional: Add transition for hover effects */
}

.btn {
    background-color: var(--color-secondary); /* Default button color */
    color: white; /* Button text color */
}

.btn-primary {
    background-color: var(--color-primary); /* Primary button color */
    color: white; /* Button text color */
}

/* Optional: Add hover effect */
.btn:hover, .btn-primary:hover {
    opacity: 0.8; /* Slightly fade on hover */
}

@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}
