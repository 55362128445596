.experience__container {
    display: flex;
    flex-direction: column; /* Stack the main container items in a column */
    gap: 2rem;
}

.experience__container > div {
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    width: 60%;
    margin: 0 auto;
}

.experience__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

/* Use flexbox to allow wrapping */
.experience__content {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping of items */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    gap: 1.5rem; /* Space between items */
}

/* Control the size of articles for proper alignment */
.experience__details {
    display: flex;
    gap: 1rem;
    align-items: center; /* Center icon and text vertically */
    width: calc(50% - 0.75rem); /* Each item takes half width minus half gap */
    min-width: 250px; /* Minimum width to maintain layout */
    text-align: center; /* Center text within the article */
}

.experience__details-icon {
    color: var(--color-primary);
}

/* Media queries for responsive design */
@media screen and (max-width: 1024px) {
    .experience__container {
        flex-direction: column;
    }

    .experience__container > div {
        width: 80%;
        margin: 0 auto;
        padding: 2rem;
    }

    .experience__content {
        flex-direction: column; /* Stack items in a column on smaller screens */
        align-items: center; /* Center all items in the column */
    }
}

@media screen and (max-width: 600px) {
    .experience__container {
        gap: 1rem;
    }

    .experience__container > div {
        width: 100%;
        padding: 2rem 1rem;
    }
}
